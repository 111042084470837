import React, { Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '@components/Loading/Loading';

const ParkerAuthLayout = () => {
    // const navigate = useNavigate();

    // if (true) navigate('/parker/login');

    return (
        <React.Fragment>
            <Suspense fallback={<Loading centered size="lg" />}>
                <Outlet />
            </Suspense>
        </React.Fragment>
    );
};

export default ParkerAuthLayout;
