import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeCollapsedForm, changeHiddenForm, changeActiveItem } from '@redux/sidebar/sidebar_slice';
import LogoutModal from '../../LogoutModal/LogoutModal';
import SidebarItem from './SidebarItem';
import './sidebar.scss';

// import all static icons
import locationIcon from '@icons/location-icon.svg';
import transactionIcon from '@icons/transaction-icon.svg';
import reportIcon from '@icons/report-icon.svg';
import lprIcon from '@icons/lpr-icon.svg';
import logoutIcon from '@icons/logout-icon.svg';

const Sidebar = () => {
    //state to handle the modal
    const [showLogoutModal, setLogoutModal] = useState(false);
    const handleLogoutModal = () => setLogoutModal((pre) => !pre);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [sideBarItems, setSidebarItems] = useState([]);
    const { transitionType, collapsedForm, hiddenForm, activeItem } = useSelector((state) => state.sidebar);

    const adminSideBarItems = [
        // Later we will un-comment these  once the feature will be ready
        {
            id: 1,
            name: 'Locations',
            iconLight: locationIcon,
            linkTo: '/admin/'
        },
        {
            id: 2,
            name: 'Transactions',
            iconLight: transactionIcon,
            linkTo: '/admin/transactions'
        },
        {
            id: 3,
            name: 'Report',
            iconLight: reportIcon,
            linkTo: '/admin/reports'
        },
        {
            id: 4,
            name: 'LPR',
            iconLight: lprIcon,
            linkTo: '/admin/lpr'
        },
        {
            id: 5,
            name: 'Logout',
            iconLight: logoutIcon
        }
    ];

    const userSideBarItems = [
        // Later we will un-comment these  once the feature will be ready
        {
            id: 1,
            name: 'Home',
            iconLight: locationIcon,
            linkTo: '/user/'
        },
        {
            id: 2,
            name: 'Locations',
            iconLight: transactionIcon,
            linkTo: '/user/locations'
        },
        {
            id: 3,
            name: 'Subscriptions',
            iconLight: reportIcon,
            linkTo: '/user/subscriptions'
        },
        {
            id: 4,
            name: 'Payments',
            iconLight: lprIcon,
            linkTo: '/user/payments'
        },
        {
            id: 5,
            name: 'Vehicles',
            iconLight: lprIcon,
            linkTo: '/user/vehicles'
        },
        {
            id: 6,
            name: 'Support',
            iconLight: lprIcon,
            linkTo: '/user/Support'
        },
        {
            id: 7,
            name: 'Account Settings',
            iconLight: lprIcon,
            linkTo: '/user/account-settings'
        },
        {
            id: 8,
            name: 'Logout',
            iconLight: logoutIcon
        }
    ];

    const handleSideBarClick = (item) => {
        if (item.name === 'Logout') {
            handleLogoutModal();
            dispatch(changeActiveItem(1)); // Dispatch the selected item to update the activeLink
        } else {
            dispatch(changeActiveItem(item.id)); // Dispatch the selected item to update the activeLink
            //if the sidebar is not collapsed or hidden on mobile screens, the sidebar will auto close on the nav click
            if (window.screen.width < 768) {
                dispatch(changeHiddenForm(true));
            } else if (window.screen.width > 768 && window.screen.width < 992 && !collapsedForm) {
                dispatch(changeCollapsedForm(true));
            }

            navigate(item.linkTo); // Navigate to the new route
        }
    };

    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setSidebarItems(adminSideBarItems);
        } else {
            setSidebarItems(userSideBarItems);
        }

        return () => {
            setSidebarItems([]);
        };
    }, []);

    const selectActiveItem = () => {
        // Determine the selected item based on the current route
        const activeItem = sideBarItems.find((item) => location.pathname === item.linkTo);
        if (activeItem) {
            dispatch(changeActiveItem(activeItem.id));
        }
    };

    useEffect(() => {
        selectActiveItem();
    }, [userSideBarItems]);

    return (
        <>
            {transitionType === 'collapse/expand' && (
                <div className={`sidebar ${collapsedForm ? 'collapse-sidebar' : 'expand-sidebar'}`}>
                    {sideBarItems.map((item) => (
                        <SidebarItem
                            key={item.id}
                            item={item}
                            selectedItemId={activeItem}
                            handleSideBarClick={handleSideBarClick}
                        />
                    ))}
                    <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                </div>
            )}
            {transitionType === 'hide/show' && (
                <div className={`sidebar ${hiddenForm ? 'hide-sidebar' : 'show-sidebar'}`}>
                    {sideBarItems.map((item) => (
                        <SidebarItem
                            key={item.id}
                            item={item}
                            selectedItemId={activeItem}
                            handleSideBarClick={handleSideBarClick}
                        />
                    ))}
                    <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                </div>
            )}
        </>
    );
};
export default Sidebar;
